import React from "react";
import logo from "./logo.svg";
import "./App.css";

const BASE_LINK = "https://github.com/julianmarmier/organize/releases/";
const VERSION_LINK = "latest/download/organize_";

function getPlatform() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

function constructLink(extension) {
  return BASE_LINK + VERSION_LINK + extension;
}

function DownloadLink() {
  const osInfo = getPlatform();
  if (osInfo === "Linux") {
    // two links
    return (
      <>
        {[
          ["AppImage (amd64)", "amd64.AppImage"],
          ["Debian (amd64)", "amd64.deb"],
          ["Arch (amd64)", "amd64.tar.zst"],
          ["AppImage (aarch64)", "aarch64.AppImage"],
          ["Debian (aarch64)", "aarch64.deb"],
          ["Arch (aarch64)", "aarch64.tar.zst"]
        ].map(([name, link]) => (
          <a key={link} className="App-link" href={constructLink(link)}>
            Download {name}
          </a>
        ))}
      </>
    );
  } else if (osInfo.operatingSystem === null) {
    // not available for your platform
    return <a className="App-link unavailable">Platform not detected.</a>;
  } else if (osInfo === "Mac OS") {
    return (
      <>
        <a
          className="App-link"
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
          href={constructLink("amd64.dmg")}
        >
         Download for macOS Intel
        </a>
        <a
          className="App-link"
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
          href={constructLink("aarch64.dmg")}
        >
          Download for macOS Apple Silicon
        </a>
      </>
    );
  } else if (osInfo == "Windows") {
   return (
        <a
          className="App-link"
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
          href={constructLink(".exe")}
        >
          Download for Windows
        </a>
   )
  }
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Organize</h1>
        <h2>The missing file manager for your computer.</h2>
        <DownloadLink />
        <p>
          {getPlatform() ? `Not downloading for ${getPlatform()}? ` : null}
          <a href={BASE_LINK + "latest"}>See all releases.</a>
        </p>
      </header>
      <img
        className="screenshot"
        src="screenshot.png"
        alt="screenshot of app"
      />
    </div>
  );
}

export default App;
